import React, { FC } from 'react';
import { Divider, Image, ImageResizeOptions, Text, TextPriority } from 'wix-ui-tpa/cssVars';
import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { useStyles } from '@wix/tpa-settings/react';
import { useCurrencyFormatter, useTranslation } from '@wix/yoshi-flow-editor';
import { usePrice } from '../../../../hooks';
import { useLocale } from '../../../../hooks/useLocale';
import { HollowCircleTick } from '../../../PackagePicker/commons/bulletIcons/HollowCircleTick';
import componentStyles from '../../stylesParams';
import { getFormattedAdditionalFees, getFreeTrialDaysLabel, getPeriodLabel, getPlanDuration } from './plan-v3-utils';
import { st, classes } from './PlanPreview.st.css';

export const PlanPreview: FC<{ plan: Plan }> = ({ plan }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const styles = useStyles();
  const planPrice = usePrice({
    value: plan.pricingVariants?.[0]?.pricingStrategies?.[0]?.flatRate?.amount,
    currency: plan.currency,
  });
  const [setupFee] = getFormattedAdditionalFees({ createCurrencyFormatter: useCurrencyFormatter, plan, locale });

  const freeTrial = getFreeTrialDaysLabel(plan, t);
  const duration = getPlanDuration(plan, t);
  const paymentFrequency = getPeriodLabel(plan, t) ?? '';

  return (
    <section className={st(classes.root, { 'with-shadow': styles.get(componentStyles.planCardEnableShadow) })}>
      {styles.get(componentStyles.showPlanCardImage) && plan.image ? (
        <div className={classes.imageContainer}>
          <Image
            fluid
            src={plan.image.id}
            sourceWidth={plan.image.width}
            sourceHeight={plan.image.height}
            resize={ImageResizeOptions.cover}
          />
        </div>
      ) : null}
      <div className={classes.previewCardContent}>
        {styles.get(componentStyles.showPlanCardName) ? <Text className={classes.name}>{plan.name}</Text> : null}
        <div>
          <div className={classes.priceContainer}>
            <Text className={classes.currency}>{planPrice.currency}</Text>
            {/* TODO: Should price have dynamic font size? */}
            <Text className={classes.price}>{planPrice.price}</Text>
          </div>
          {paymentFrequency && (
            <Text className={classes.frequency} tagName="div">
              {paymentFrequency}
            </Text>
          )}
          {setupFee && (
            <Text tagName="div" className={classes.setupFee}>
              + {setupFee.amount} {setupFee.name}
            </Text>
          )}
        </div>
        {styles.get(componentStyles.showPlanCardDescription) && plan.description ? (
          <Text tagName="div" className={classes.description}>
            {plan.description}
          </Text>
        ) : null}
        <Text tagName="div" priority={TextPriority.secondary} className={classes.duration}>
          <span>{duration}</span>
          {freeTrial ? <div>{freeTrial}</div> : null}
        </Text>
        {styles.get(componentStyles.showPlanCardPerks) && plan.perks?.length ? (
          <>
            <Divider className={classes.perksDivider} />
            <ul className={classes.perksList}>
              {plan.perks.map((perk) => (
                <li key={perk.id} className={classes.perk}>
                  {/* TODO: Check what icon to use here */}
                  <HollowCircleTick size={21} className={classes.perkIcon} />
                  <Text className={classes.perkText}>{perk.description}</Text>
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </section>
  );
};
